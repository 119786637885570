import React, { useState, useContext, useEffect } from "react";

const LanguageContext = React.createContext();
export const useLanguageContext = () => useContext(LanguageContext);

export default function LanguageContextProvider({ languageCookie, children }) {
  const [language, changeLanguage] = useState(languageCookie);


  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]); 


  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}