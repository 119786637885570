import React from "react";
import Grid from '@mui/material/Grid';
import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';







const Footer = () => {
    return (
        <AppBar elevation={0} sx={{ background: "rgb(248,249,252)", top: 'auto', bottom: 0 }} >
            <Toolbar sx={{ justifyContent: "center" }}>
                <Box component="img" height="48px" sx={{ padding: '8px' }}
                     src="/images/static/MERCURYSuite_DigitalEvents.png"
                />
            </Toolbar>
        </AppBar>
    );
}


const Loading = () => {
    return (
        <Box display="flex" justifyContent="center" flexDirection="column" minHeight="100vh" sx={{ background: "#d61c27" }}>
            <Box sx={{ padding: "22px" }} flex={1} justifyContent="center" flexDirection="column" display="flex">
                <Grid container justifyContent="center" alignItems="center"  >
                    {/**
                    <Grid item xs={12}>
                        <Box component="img" sx={{ height: '260px', objectFit: 'contain' }}
                            src="/images/static/logo_mercury.png"
                        />
                    </Grid>
                     */}
                    <Grid item xs={12}>
                        <Box mt={6}>
                            <CircularProgress size="3rem" style={{'color': 'white'}}/>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    );
}




const LoadingLogo = ({ imgLogo }) => {
    return (
        <Box display="flex" justifyContent="center" flexDirection="column" minHeight="100vh" sx={{ background: "#d61c27" }}>
            <Box sx={{ padding: "22px" }} flex={1} justifyContent="center" flexDirection="column" display="flex">
                <Grid container justifyContent="center" alignItems="center"  >
                    <Grid item xs={12}>
                        <Box component="img" sx={{ height: '260px', objectFit: 'contain' }}
                            src={imgLogo}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={6}>
                            <CircularProgress size="3rem" style={{'color': 'white'}}/>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

        </Box>
    );
}

export { Loading, LoadingLogo };




