import React, { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import Page404 from "../pages/Page404";
import { getItemFromDB, getImgFileFromBucketS3 } from "../aws/lambda/DBManager";
import { Loading, LoadingLogo } from "../pages/Loading";
import Countdown from "../pages/Countdown";
import PageCountdownStart from "../pages/PageCountdownStart";



const RequireEventID = ({ children, templateData, setTemplateData, eventData, setEventData }) => {
    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showChildren, setShowChildren] = useState(false);
    const [showLoadingLogo, setShowLoadingLogo] = useState(false);
    const [showEventCountdown, setShowEventCountdown] = useState(false);
    const [searchParams] = useSearchParams();



    async function getImgLogo(templatePageID, templateData, setTemplateData) {
        await getImgFileFromBucketS3("pdeTemplate/" + templatePageID + "/", "loading_company_logo.png").then(data => {
            if (data.statusCode === undefined && data !== "data:image/png;base64,") {
                setTemplateData(templateData => ({ ...templateData, loading_company_logo: data }))
                setShowLoading(false);
                setShowLoadingLogo(true);
            }
        });
    }
    
    
    async function getImgPdeTemplate(templatePageID, templateData, setTemplateData) {

        await getImgFileFromBucketS3("pdeTemplate/" + templatePageID + "/", "company_logo.png").then(data => {
            if (data.statusCode === undefined && data !== "data:image/png;base64,") {
                setTemplateData(templateData => ({ ...templateData, company_logo: data }))
            }
        });
    
        await getImgFileFromBucketS3("pdeTemplate/" + templatePageID + "/", "bg_land.png").then(data => {
            if (data.statusCode === undefined && data !== "data:image/png;base64,") {
                setTemplateData(templateData => ({ ...templateData, bg_land: data }))
            }
        });
    
        await getImgFileFromBucketS3("pdeTemplate/" + templatePageID + "/", "fg_land.png").then(data => {
            if (data.statusCode === undefined && data !== "data:image/png;base64,") {
                setTemplateData(templateData => ({ ...templateData, fg_land: data }))
            }
        });

        await getImgFileFromBucketS3("pdeTemplate/" + templatePageID + "/", "bg_port.png").then(data => {
            if (data.statusCode === undefined && data !== "data:image/png;base64,") {
                setTemplateData(templateData => ({ ...templateData, bg_port: data }))
            }
        });
    
        await getImgFileFromBucketS3("pdeTemplate/" + templatePageID + "/", "fg_port.png").then(data => {
            if (data.statusCode === undefined && data !== "data:image/png;base64,") {
                setTemplateData(templateData => ({ ...templateData, fg_port: data }))
            }
        });
    }



    useEffect(() => {
        const interval = setInterval(() => {
                let date = new Date().getTime();
             
                if(showEventCountdown === true && date >= eventData.startDate){                    
                    setShowEventCountdown(false);
                    setShowChildren(true);
                } 

        }, 1000);

        return () => clearInterval(interval);
    }, [eventData, showEventCountdown]);




    //http://localhost:3000/?event=xxx
    useEffect((session) => {
        try {
            if (searchParams.get('event').length > 0 /*&& searchParams.get('user').length > 0*/) {
                getItemFromDB("event", searchParams.get('event')).then(data => {
                    (async () => {
                        setEventData(data.Item);
                        let startDate = data.Item.startDate;

                        //cattura il template dell'evento
                        getItemFromDB("template_pde", data.Item.pageTemplate.eventPage).then(data => {
                            let templatePDEData = data.Item;

                            if (templatePDEData.button_color !== undefined)
                                setTemplateData(templateData => ({ ...templateData, button_color: templatePDEData.button_color }))

                            if (templatePDEData.company_logo_position !== undefined)
                                setTemplateData(templateData => ({ ...templateData, company_logo_position: templatePDEData.company_logo_position }))

                            if (templatePDEData.mercury_logo_color !== undefined)
                                if (templatePDEData.mercury_logo_color === "black")
                                    setTemplateData(templateData => ({ ...templateData, mercury_logo: "/images/static/MERCURYSuite_DigitalEvents_nero.png" }))
                                else if (templatePDEData.mercury_logo_color === "white")
                                    setTemplateData(templateData => ({ ...templateData, mercury_logo: "/images/static/MERCURYSuite_DigitalEvents_bianco.png" }))
                        });

                        await getImgLogo(data.Item.pageTemplate.eventPage, templateData, setTemplateData);
                        await getImgPdeTemplate(data.Item.pageTemplate.eventPage, templateData, setTemplateData);
                        setShowError(false);
                        setShowLoading(false);
                        setShowLoadingLogo(false);

                        const user = searchParams.get('user');
                       
                
                        //controlla lista backdoor
                        const userBackdoorList = data.Item.loginBackdoor || "$$$$$$$$$$$$$$$$$$$$$$$";
                        const userBackdoors = userBackdoorList.split("||").map(item => item.trim());
                


                        let date = new Date().getTime()


                        if(userBackdoors.includes(user)){
                            setShowEventCountdown(false);
                            setShowChildren(true);
                        } else 
                        
                        if(date < startDate){
                            setShowEventCountdown(true);
                            setShowChildren(false);
                        } else if (date >= startDate){
                            setShowEventCountdown(false);
                            setShowChildren(true);
                        }

                        

                
                    })();
                });
            }
            else {
                setShowError(true);
                setShowLoading(false);
                setShowLoadingLogo(false);
                setShowChildren(false);
                setShowEventCountdown(false);
            };

 

        } catch (e) {
            setShowError(true);
            setShowLoading(false);
            setShowLoadingLogo(false);
            setShowChildren(false);
            setShowEventCountdown(false);
        }
    }, []);





    return (
        <>
            <div style={{ display: showLoading ? 'block' : 'none' }}>
                <Loading />
            </div>
            <div style={{ display: showLoadingLogo ? 'block' : 'none' }}>
                <LoadingLogo imgLogo={templateData.loading_company_logo} />
            </div>
            <div style={{ display: showError ? 'block' : 'none' }}>
                <Page404 />
            </div>
            <div style={{ display: showEventCountdown ? 'block' : 'none' }}>
                <PageCountdownStart pageTemplateData={templateData} eventData={eventData}/>
            </div>
            <div style={{ display: showChildren ? 'block' : 'none' }}>
                {children}
            </div>
        </>
    );
};

export default RequireEventID;