import React, { createContext } from "react"
import { Buffer } from 'buffer';
import { userLogin, userLogout } from "../aws/lambda/DBManager";

import { publicIpv4 } from 'public-ip';



function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (/Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}


const browserName = (function (agent) {
    switch (true) {
        case agent.indexOf("edge") > -1: return "MS Edge";
        case agent.indexOf("edg/") > -1: return "Edge ( chromium based)";
        case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
        case agent.indexOf("trident") > -1: return "MS IE";
        case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
        case agent.indexOf("safari") > -1: return "Safari";
        default: return "other";
    }
})(window.navigator.userAgent.toLowerCase());






const AccountContext = createContext();

const AuthenticationProvider = (props) => {
    //cattura sessione esistente
    const getSession = async () => {
        let data = await localStorage.getItem('token');

        if (data === null) {
            //throw new Error(400);
            return null;
        }
        else return data;
    };


    //funzione di login
    const loginUser = async (EventID, Email, Password) => {

        let ClientIP = "**not available**";
        let OS = "**not available**";
        let ClientWeb = "**not available**";

        try {
            ClientIP = await publicIpv4();
        } catch (error) {
            console.log("error to get publicIP");
        }

        try {
            OS = getOS();
        } catch (error) {
            console.log("error to get OS");
        }

        try {
        ClientWeb = browserName;
    } catch (error) {
        console.log("error to get clientWeb");
    }

        let item = {
            eventId: EventID,
            email: Email,
            password: Password,
            clientIP: ClientIP,
            operatingSystem: OS,
            clientWeb: ClientWeb,
        }

        console.log("*** USER LOGIN REQUEST ***");

        return await new Promise((resolve, reject) => {
            userLogin(item)
                .then((data) => {
                    if (data.item.length > 0 && data.item !== "user already logged in") {
                        localStorage.setItem("token", data.item)
                    }
                    resolve(data);
                })
                .catch(error => {
                    console.log(error)
                    reject(error);
                })
        })
    };

    //funzione di logout
    const logoutUser = async (EventID, Email) => {

        let item = {
            eventId: EventID,
            email: Email,
        }

        await new Promise((resolve, reject) => {
            userLogout(item)
                .then((data) => {
                    //resolve(data);
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    window.location.reload();
                })
                .catch(error => {
                    //reject(error);
                    console.log(error);
                })
        });
    };

    //funzione che ritorna il nome dell'utente
    const getAccountData = async () => {
        let token = await localStorage.getItem('token');

        if (token === null) {
            return null;
        }

        let base64Payload = token.split('.')[1];
        let payload = Buffer.from(base64Payload, 'base64');
        let content = JSON.parse(payload.toString());
        return content.accountData;
    };

    return (
        <AccountContext.Provider value={{ getSession, loginUser, logoutUser, getAccountData }}>
            {props.children}
        </AccountContext.Provider>
    );
};

export { AuthenticationProvider, AccountContext };