import MercuryResponsivePage from "../components/MercuryResponsivePage";
import React, { useContext, useEffect, useRef, useState } from "react";
import Grid from '@mui/material/Grid';
import { useSearchParams } from "react-router-dom";
import { confirmUserResetsTheirPassword } from "../aws/lambda/DBManager";
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';



const ResetPassword = ({ pageTemplateData }) => {
    const imageRef = useRef(null);

    const navigate = useNavigate();

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [searchParams] = useSearchParams();
    const [message, setMessage] = React.useState("Recupero del link di accesso in corso...");
    const [messageClose, setMessageClose] = React.useState("Recupero del link di accesso in corso...");


    const isLandscape = windowSize.width > windowSize.height;

    const thresholdWidth = isLandscape ? 1000 : 689;
    const thresholdHeight = isLandscape ? 736 : 1044;

    const calculateMaxWidth = () => {
        if (windowSize.height >= thresholdHeight) {
            return thresholdWidth;
        } else {
            const widthPercentage = (windowSize.height / thresholdHeight) * 100;
            return (thresholdWidth * widthPercentage) / 100;
        }
    };

    const calculateMaxHeight = () => {
        if (windowSize.width >= thresholdWidth) {
            return thresholdHeight;
        } else {
            const heightPercentage = (windowSize.width / thresholdWidth) * 100;
            return (thresholdHeight * heightPercentage) / 100;
        }
    };


    const calculatePadding = () => {
        if (isLandscape) {
            return '40px';
        } else {
            return '18px';
        }
    };


    const overlayStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        padding: calculatePadding(),
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: calculateMaxWidth(),
        height: '100%',
        maxHeight: calculateMaxHeight(),
        //border: '2px solid #fff',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 0
    };

    const innerDivStyle = {
        position: 'relative',
        width: '100%',
        maxWidth: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    };

    useEffect(() => {

        const event = searchParams.get('event');
        const user = searchParams.get('user');
        const authorizationCode = searchParams.get('authorizationCode');
        const userMod = user.replace("+", "%2B");
        let url = '/?event=' + event + '&user=' + userMod;
        
        if (event !== null && user !== null && authorizationCode !== null) {
            alert("Sei davvero sicuro di voler resettare il link di accesso?")
            confirmUserResetsTheirPassword(event, user, authorizationCode)
                .then((data) => {
                    console.log("xsd_send_confirm");
                    if (data.message === "Error: user not find") {
                        setMessage("Utente non trovato, impossibile effettuare il recupero del link di accesso. Contattare l'amministratore di sistema")
                    } else if (data.message === "Error: authorization code is not valid") {
                        setMessage("Codice di autorizzazione non valido, impossibile effettuare il recupero del link di accesso. Contattare l'amministratore di sistema")
                    } else if (data.message === "Reinvio credenziali utente avvenuto con successo") {
                        setMessage("Il recupero del link di accesso è avvenuto con successo. Abbiamo inviato una email al suo indirizzo di posta con il link per accedere all'evento")
                    }
                })
                .catch((err) => {
                    setMessage("Impossibile effettuare il recupero del link di accesso, contattare l'amministratore di sistema")
                });
        } else {
            setMessage("Impossibile effettuare il recupero del link di accesso, contattare l'amministratore di sistema.")
        }
        setMessageClose("")
        /*
        setTimeout(() => {
            window.location.href = url;
        }, 10000); //timer di 10 secondi
        */
    }, [searchParams]);


    return (
        <MercuryResponsivePage pageElements={pageTemplateData} loginMode={false}>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={12}>
                    <Paper style={{ alignItems: 'center', justifyContent: 'center', paddingLeft: '8px', paddingRight: '8px', backgroundColor: "transparent", boxShadow: '0 0 0 0', position: 'relative' }}>
                        <div style={overlayStyle}>
                            <div style={innerDivStyle}>
                                <Typography className="font-replicatt-xl" color="white" >{message}</Typography>
                                <Typography className="font-replicatt-xl" color="white" >{messageClose}</Typography>
                            </div>
                        </div>
                        <img

                            ref={imageRef}
                            src={
                                isLandscape ? pageTemplateData.fg_land : pageTemplateData.fg_port}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100vh',
                                display: 'block',
                                margin: 'auto',
                            }}
                        >
                        </img>
                    </Paper>
                </Grid>
            </Grid>
        </MercuryResponsivePage>
    );
}

export default ResetPassword;
