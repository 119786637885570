import React from "react";
import { useState, useEffect } from 'react'
import { Box, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import MercuryHeaderButton from "./MercuryHeaderButton";


function getDeviceOrientation() {

    if (window.screen.orientation) {
        return window.screen.orientation.type;
    }

    // iOS/safari
    return Math.abs(+window.orientation) === 90 ? 'landscape-primary' : 'portrait-primary';
}


function useScreenOrientation() {
    const [orientation, setOrientation] = useState(getDeviceOrientation());


    useEffect(() => {
        const handleOrientationChange = () => setOrientation(getDeviceOrientation());
        window.addEventListener('orientationchange', handleOrientationChange);
        return () => window.removeEventListener('orientationchange', handleOrientationChange);
    }, []);

    return orientation;
}


const Header = ({ pageElements, showAccountButton, accountData, portraitMode }) => {
    return (
        <>
            <AppBar position="absolute" elevation={0} sx={{ background: 'transparent' }}>
                <Toolbar sx={{ height: "90px" }}>
                    <MercuryHeaderButton portraitMode={portraitMode} buttonColor={pageElements.button_color} accountData={accountData} showAccountButton={showAccountButton} />
                    </Toolbar>
            </AppBar>

            <AppBar elevation={0} position="relative" sx={{ background: 'transparent', height: "90px" }}>
                {pageElements.company_logo_position === "center" ?
                    <Toolbar sx={{ justifyContent: "center", height: "90px" }}>
                        <Box component="img" height="90px"
                            //src={pageElements.company_logo}
                        />
                    </Toolbar> :
                    <Toolbar sx={{ justifyContent: "left", height: "90px" }}>
                        <Box component="img" height="90px"
                            //src={pageElements.company_logo}
                        />
                    </Toolbar>
                }
            </AppBar>
        </>
    );
}



const Footer = ({ pageElements }) => {
    return (
        <>

        </>
    );
}


const MercuryResponsivePage = ({ pageElements, children, loginMode, accountData }) => {

    const [isPortrait, setIsPortrait] = useState(false);
  
    useEffect(() => {
        let orientation = getDeviceOrientation();
        if(orientation === "portrait-primary"){
            setIsPortrait(true)
        } else setIsPortrait(false)
    }, [useScreenOrientation()]);



    return (

        <Box display="flex" justifyContent="center" flexDirection="column" minHeight="100vh" sx={{ background: "url(" + pageElements.bg_land + ")", backgroundSize: "cover" }}>


{loginMode ?  <Header portraitMode={false} pageElements={pageElements} showAccountButton={false} accountData={accountData} /> :
            accountData ? 
            <Header portraitMode={isPortrait} pageElements={pageElements} showAccountButton={true} accountData={accountData} /> :
            <Header portraitMode={isPortrait} pageElements={pageElements} showAccountButton={false} accountData={{}} />
            }
                <Box flex={1} marginTop='-90px' justifyContent="center" flexDirection="column" display="flex">
                    {children}
                </Box>
                <Footer pageElements={pageElements} />
            </Box>
          
    );
}

export default MercuryResponsivePage;
