import MercuryResponsivePage from "../components/MercuryResponsivePage";
import Countdown from "./Countdown";
import EndEvent from "./EndEvent";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import Grid from '@mui/material/Grid';
import { Box, Button, Typography } from '@mui/material';
import { CardContent, Card } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useTranslation from "../localization/Translations";
import Fade from '@mui/material/Fade';
import { AccountContext } from "../authentication/AuthenticationProvider";
import LogoutIcon from '@mui/icons-material/Logout';
import { publicIpv4 } from 'public-ip';
import { getNetworkAddressListFromDB } from "../aws/lambda/DBManager";
import WowzaFlowplayer from "../components/WowzaFlowplayer";
import Paper from '@mui/material/Paper';
import { useSearchParams } from "react-router-dom";

const Home = ({ pageTemplateData, eventData }) => {

    const [searchParams] = useSearchParams();

    const imageRef = useRef(null);
    //const [searchParams] = useSearchParams();

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });


    const isLandscape = windowSize.width > windowSize.height;

    const thresholdWidth = isLandscape ? 1000 : 689;
    const thresholdHeight = isLandscape ? 736 : 1044;

    const calculateMaxWidth = () => {
        if (windowSize.height >= thresholdHeight) {
            return thresholdWidth;
        } else {
            const widthPercentage = (windowSize.height / thresholdHeight) * 100;
            return (thresholdWidth * widthPercentage) / 100;
        }
    };

    const calculateMaxHeight = () => {
        if (windowSize.width >= thresholdWidth) {
            return thresholdHeight;
        } else {
            const heightPercentage = (windowSize.width / thresholdWidth) * 100;
            return (thresholdHeight * heightPercentage) / 100;
        }
    };


    const calculatePadding = () => {
        if (isLandscape) {
            return '40px';
        } else {
            return '18px';
        }
    };


    const calculateMarginTop = () => {
        if (windowSize.height >= 620) {
            return '22px';
        } else {
            return '0px';
        }
    };


    const calculateButtonPosition = () => {
        if (windowSize.height >= 620) {
            return '32px';
        } else {
            return '14px';
        }
    };


    const overlayStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        padding: calculatePadding(),
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: calculateMaxWidth(),
        height: '100%',
        maxHeight: calculateMaxHeight(),
        //border: '2px solid #fff',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 0
    };


    const innerDivStyle = {
        position: 'relative',
        width: '100%',
        marginTop: calculateMarginTop(),
        //backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    };

    const ipRangeCheck = require("ip-range-check");

    const { getAccountData, logoutUser } = useContext(AccountContext);

    const translation = useTranslation();
    const [accountData, setAccountData] = React.useState(getAccountData);
    const [showLoading, setShowLoading] = React.useState(true);
    const [showEventCountdown, setShowEventCountdown] = React.useState(false);
    const [showEventPlayer, setShowEventPlayer] = React.useState(false);
    const [showEventEnd, setShowEventEnd] = React.useState(false);
    const [accountID, setAccountID] = React.useState("");


    //video url (public or private)
    const [streamingVideoURL, setStreamingVideoURL] = React.useState("");

    useEffect(() => {
        getAccountData()
            .then((data) => {
                setAccountData(data);
                if (eventData.showUserIDOnVideo) {
                    setAccountID("ID: " + data.additionalInfo.idx);
                }
            })
            .catch((error) => {
                //alert("errore interno, contattare l'amministratore di sistema");
                setAccountID("GUEST")
                //setShowEventPlayer(true)
            });
    }, [getAccountData, eventData]);


    useEffect(() => {
        const setVideoSourceByIPAddress = async () => {
            //cattura l'ip pubblico della rete

            console.log("System language: " + translation.language);

            let publicIP = "";

            try {
                publicIP = await publicIpv4();
            } catch (err) {
                alert("Impossibile recuperare l'indirizzo IP, contattare l'amministratore di sistema");
            }

            //cattura la lista degli ip (+subnet) configurati sul DB dal tenant
            getNetworkAddressListFromDB().then(data => {
                let IPArray = [];

                for (let i = 0; i < data.Items.length; i++) {
                    IPArray.push(data.Items[i].address)
                }

                //controlla se l'ip fa parte del range
                let ipChecker = ipRangeCheck(publicIP, IPArray)

                if (ipChecker === true) {
                    //imposta lo streaming privato se c'è un link privato valido, altrimenti mostra quello pubblico
                    console.log("***    local connection    ***")
                    if (eventData.videoStreamingLocalUrl === undefined) {
                        console.log("local video URL is not defined <::> show public video URL");
                        if (translation.language === 'en') {
                            setStreamingVideoURL(eventData.engVideoStreamingWideUrl);
                            console.log("Video language: " + translation.language);
                        } else {
                            setStreamingVideoURL(eventData.videoStreamingWideUrl);
                        }
                    } else {
                        console.log("show private video URL");
                        if (translation.language === 'en') {
                            setStreamingVideoURL(eventData.engVideoStreamingLocalUrl)
                            console.log("Video language: " + translation.language);
                        } else {
                            setStreamingVideoURL(eventData.videoStreamingLocalUrl)
                        }
                    }
                } else {
                    //imposta lo streaming pubblico
                    if (translation.language === 'en') {
                        setStreamingVideoURL(eventData.engVideoStreamingWideUrl)
                        console.log("Video language: " + translation.language);
                    } else {
                        setStreamingVideoURL(eventData.videoStreamingWideUrl)
                    }
                }
            });

        }

        setVideoSourceByIPAddress();
    }, [eventData, translation]);


    useEffect(() => {
        const intervalId = setInterval(() => {
            let currentDate = Date.now();
            const user = searchParams.get('user');

               //controlla lista backdoor
               const userBackdoorList = eventData.loginBackdoor || "$$$$$$$$$$$$$$$$$$$$$$$";
               const userBackdoors = userBackdoorList.split("||").map(item => item.trim());
       
               if(userBackdoors.includes(user)){
                setShowLoading(false);
                setShowEventCountdown(false);
                setShowEventEnd(false);
                setShowEventPlayer(true);
               } else 

            //se la data corrente è maggiore della data inizio evento mostra lo streaming
            if (currentDate >= eventData.startDate && currentDate <= eventData.endDate) {
                setShowLoading(false);
                setShowEventCountdown(false);
                setShowEventEnd(false);
                setShowEventPlayer(true);

            } else if (currentDate < eventData.startDate) { //viceversa mostra il countdown
                setShowLoading(false);
                setShowEventCountdown(true);
                setShowEventEnd(false);
                setShowEventPlayer(false);
            }

            //se la data corrente è maggiore della fine dell'evento mostra messaggio fine trasmissione
            else if (currentDate > eventData.endDate) {
                setShowLoading(false);
                setShowEventCountdown(false);
                setShowEventEnd(true);
                setShowEventPlayer(false);
            }
        }, 1000);
        return () => { clearInterval(intervalId); };
    }, [eventData]);


    const handleClickLogoutButton = () => {
        logoutUser(eventData.id, accountData.email);
    }



    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const memoizedPlayer = useMemo(() => (
        <WowzaFlowplayer streamingURL={streamingVideoURL} textOverlay={accountID} />
    ), [streamingVideoURL]);


    return (
        <MercuryResponsivePage pageElements={pageTemplateData} accountData={accountData} loginMode={false}>
        {/**
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" alignItems="center"  >
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding: 1 }}>
                        <Card sx={{
                            width: {
                                xs: 320, // theme.breakpoints.up('xs')
                                sm: 500, // theme.breakpoints.up('sm')
                                md: 600, // theme.breakpoints.up('md')
                                lg: 800, // theme.breakpoints.up('lg')
                                xl: 900, // theme.breakpoints.up('xl')
                            }, boxShadow: 20, padding: 2, borderRadius: '8px', background: "rgb(248,249,252)"
                        }}>
                            <Typography mt={1.5} mb={1} variant="h4">{eventData.name}</Typography>
                            <CardContent sx={{ background: "rgb(248,249,252)", paddingBottom: "0px !important" }}>
                                <Box display="flex" justifyContent="center" alignItems="center" style={{ display: showEventPlayer ? 'block' : 'none' }}>
                                    <WowzaFlowplayer streamingURL={streamingVideoURL} textOverlay={accountID} />
                                    {accountData ?
                                        <Button variant="outlined" startIcon={<LogoutIcon />} sx={{ marginTop: "40px" }} onClick={() => { handleClickLogoutButton(); }}>{translation.header_logout}</Button>
                                        : <Box sx={{ marginTop: "40px" }}></Box>
                                    }
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center" style={{ display: showEventCountdown ? 'block' : 'none' }}>
                                    <Countdown eventStartDate={eventData.startDate} />
                                    {accountData ?
                                        <Button variant="outlined" startIcon={<LogoutIcon />} sx={{ marginTop: "40px" }} onClick={() => { handleClickLogoutButton(); }}>{translation.header_logout}</Button>
                                        : <Box sx={{ marginTop: "40px" }}></Box>
                                    }
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center" style={{ display: showEventEnd ? 'block' : 'none' }}>
                                    <EndEvent />
                                    {accountData ?
                                        <Button variant="outlined" startIcon={<LogoutIcon />} sx={{ marginTop: "40px" }} onClick={() => { handleClickLogoutButton(); }}>{translation.header_logout}</Button>
                                        : <Box sx={{ marginTop: "40px" }}></Box>
                                    }
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center" style={{ display: showLoading ? 'block' : 'none' }}>
                                    <Typography variant="h8" color="text.secondary"> {translation.home_pleasewait} </Typography>
                                    <Box mt={5}>
                                        <Fade in={true} {...(true ? { timeout: 800 } : {})}>
                                            <CircularProgress size={50} />
                                        </Fade>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
             */}


<Grid container justifyContent="center" alignItems="center" >


<Grid item xs={12} sx={{ display: showEventPlayer ? 'block' : 'none' }}>
    <Paper style={{ alignItems: 'center', justifyContent: 'center', paddingLeft: '8px', paddingRight: '8px', backgroundColor: "transparent", boxShadow: '0 0 0 0', position: 'relative' }}>
        <div style={overlayStyle}>
        {accountData ?
            <Button className="gsd_logout_button" variant="outlined" style={{ position: 'absolute', bottom: calculateButtonPosition(), textAlign: 'center' }} onClick={() => { handleClickLogoutButton(); }}>{translation.header_logout}</Button>
            : <Box sx={{ marginTop: "40px" }}></Box>
        }
            
            <div style={innerDivStyle}>
             
                {memoizedPlayer}

            </div>
        </div>
        <img

            ref={imageRef}
            src={
                isLandscape ? pageTemplateData.fg_land : pageTemplateData.fg_port}
            style={{
                maxWidth: '100%',
                maxHeight: '97vh',
                display: 'block',
                margin: 'auto',
            }}
        >
        </img>
    </Paper>
</Grid>


<Grid item xs={12} sx={{ display: showEventEnd ? 'block' : 'none' }}>
    <Paper style={{ alignItems: 'center', justifyContent: 'center', paddingLeft: '8px', paddingRight: '8px', backgroundColor: "transparent", boxShadow: '0 0 0 0', position: 'relative' }}>
        <div style={overlayStyle}>     
        {accountData ?   
            <Button className="gsd_logout_button" variant="outlined" style={{ position: 'absolute', bottom: calculateButtonPosition(), textAlign: 'center' }} onClick={() => { handleClickLogoutButton(); }}>{translation.header_logout}</Button>
            : <Box sx={{ marginTop: "40px" }}></Box>
        }
           <div>
                <Typography className="font-replicatt-xl" color="white" >L'evento è terminato</Typography>
                <Typography className="font-replicatt-xl" color="white" >Grazie per la visione</Typography>
            </div>
        </div>
        <img

            ref={imageRef}
            src={
                isLandscape ? pageTemplateData.fg_land : pageTemplateData.fg_port}
            style={{
                maxWidth: '100%',
                maxHeight: '97vh',
                display: 'block',
                margin: 'auto',
            }}
        >
        </img>
    </Paper>
</Grid>


</Grid>
        </MercuryResponsivePage>
    );
}

export default Home;
