import { useRef, useEffect, useState } from "react"
import Flowplayer, { useFlowplayer } from "@flowplayer/react-flowplayer"
import HLSPlugin from "@flowplayer/player/plugins/hls"
import flowplayer from "@flowplayer/player"
import React from "react";
import "@flowplayer/player/flowplayer.css";

flowplayer(HLSPlugin);

const WowzaFlowplayer = ({ streamingURL, textOverlay }) => {

  const playerRef = useRef(null)
  const [isDivAdded, setDivAdded] = useState(false);

  useEffect(() => {
    if (!isDivAdded) {
      const newDiv = document.createElement('div');
      newDiv.className = 'text-overlay-flowplayer';

      const textNode = document.createTextNode(textOverlay);
      newDiv.appendChild(textNode);

      const existingElement = document.querySelector('.fp-left');
      existingElement.appendChild(newDiv);

      const videoElement = document.querySelector('.fp-engine');
      if (videoElement) {
        videoElement.setAttribute('webkit-playsinline', 'true');
        videoElement.setAttribute('playsinline', 'true');
        videoElement.setAttribute('playsInline', 'true');
      }

      setDivAdded(true);
    } else {
      const textNode = document.querySelector('.text-overlay-flowplayer').firstChild;
      textNode.nodeValue = textOverlay;
    }
  }, [isDivAdded, textOverlay]);


  //https://docs.flowplayer.com/player/configuration
  /*16 (USE_DRAG_HANDLE)  + 128 (USE_THIN_CONTROLBAR) + 2048 (NO_DURATION)*/
  const uiValue = 2192

  const options = {
    asel: true,
    auto_oriented: true,
    controls: true,
    lang: 'it',
    live: true,
    start_quality: 4,
    disabled: false,
    autopause: false,
    aspectRatio: "1:1",
    ui: uiValue
  }


  return (
    <Flowplayer
      width='100%'
      height='100%'
      token={
        "eyJraWQiOiJsUGlxOUFnZ2paUWMiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJsUGlxOUFnZ2paUWNcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.c0yvbB9vdSXVydRN_Q-1EUMQT7541pbGnr-NNsfQJtNUNlscLKlC7DWIO8PGNuNluSFWqzEo9jgaNKnUtaN-8Q"
      }
      src={[
        {
          src: streamingURL,
          type: "video/mp4"
        }
      ]}
      opts={options}
      ref={playerRef}
      title={"flowplayer video"}
    >
    </Flowplayer>
  );
}

export default WowzaFlowplayer;