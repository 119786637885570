import configData from "../../config";


const getItemFromDB = (itemType, id) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "command": "getItemFromDB",
            "itemType": itemType,
            "id": id
        })
    };

    return fetch(configData.APIGateway_MercuryStreamLambda, requestOptions)
        .then(response => response.json())
        .then(data => data.item)
        .catch(_ => console.log("Impossibile inviare i dati"));
}


const userLogin = (item) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "command": "signInUser",
            "item": item
        })
    };

    return fetch(configData.APIGateway_MercuryStreamLambda, requestOptions)
        .then(response => response.json())
        .catch(_ => console.log("Impossibile inviare i dati"));
}



const userLogout = (item) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "command": "logoutUser",
            "item": item
        })
    };

    return fetch(configData.APIGateway_MercuryStreamLambda, requestOptions)
        .then(response => response.json())
        .then(data => data.item)
        .catch(err => console.log(err));
}


const getImgFileFromBucketS3 = (filePath, fileName) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "command": "getImgFileFromBucketS3",
            "filePath": filePath,
            "fileName": fileName
        })
    };

    return fetch(configData.APIGateway_MercuryStreamLambda, requestOptions)
        .then(response => response.json())
        .then(data => data.item)
        .catch(_ => console.log("Impossibile inviare i dati"));
}


const getNetworkAddressListFromDB = () => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "command": "getNetworkAddressList",
        })
    };

    return fetch(configData.APIGateway_MercuryStreamLambda, requestOptions)
        .then(response => response.json())
        .then(data => data.item)
        .catch(_ => console.log("Impossibile inviare i dati"));
}



const userResetsTheirPassword = (EventID, Email) => {

    let item = {
        eventId: EventID,
        email: Email,
    }

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "command": "userResetsTheirPassword",
            "item": item
        })
    };

    return fetch(configData.APIGateway_MercuryStreamLambda, requestOptions)
        .then(response => response.json())
        .catch(_ => console.log("Impossibile inviare i dati"));
}



const confirmUserResetsTheirPassword = (EventID, Email, Code) => {

    let item = {
        eventId: EventID,
        email: Email,
        code: Code
    }


    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "command": "confirmUserResetsTheirPassword",
            "item": item
        })
    };

    return fetch(configData.APIGateway_MercuryStreamLambda, requestOptions)
        .then(response => response.json())
        .catch(_ => console.log("Impossibile inviare i dati"));
}


export { getItemFromDB, getNetworkAddressListFromDB, userLogin, userLogout, getImgFileFromBucketS3, userResetsTheirPassword, confirmUserResetsTheirPassword };
