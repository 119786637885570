import React, { useState, useContext, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField, Box, InputAdornment, IconButton, Typography, Button } from '@mui/material';
import { CardContent, Card } from '@mui/material';
import useTranslation from "../localization/Translations";
import { AccountContext } from "../authentication/AuthenticationProvider";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSearchParams } from "react-router-dom";
import MercuryResponsivePage from "../components/MercuryResponsivePage";
import { styled } from "@mui/material/styles";
import Paper from '@mui/material/Paper';
import { userResetsTheirPassword } from "../aws/lambda/DBManager";
import { useRef } from 'react';



const StyledTextField = styled(TextField)({
    "& label": {
        color: "white"
    },

    "& label.Mui-focused": {
        color: "white"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "white"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white"
        },
        "&:hover fieldset": {
            borderColor: "white",
            borderWidth: 2
        },
        "&.Mui-focused fieldset": {
            borderColor: "white"
        }
    }
});



const Login = ({ pageTemplateData, eventData }) => {

    const translation = useTranslation();
    const { loginUser } = useContext(AccountContext);
    const [searchParams] = useSearchParams();

    //campi del form
    //const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //mostra - nascondi password
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    //funzioni ux del login
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [buttonLoadingText, setButtonLoadingText] = useState(translation.login_button);


    useEffect(() => {
        setButtonLoadingText(translation.login_button);
    }, [translation]);

    const onSubmit = (event) => {
        event.preventDefault();
        setButtonLoadingText(translation.login_button_wait);
        setLoadingButton(true);

        document.getElementById('loginText').style.color = "black";
        document.getElementById('loginText').textContent = "";

        //loginUser(searchParams.get('event'), searchParams.get('user'), password)
        loginUser(searchParams.get('event'), searchParams.get('user'), password)
            .then((data) => {
                if (data.message === 'Error: Cookie found, login refused. The user already logged in') {
                    console.log(data.message)
                    document.getElementById('loginText').style.color = "red";
                    document.getElementById('loginText').textContent = translation.login_text_already_logged
                } else

                    if (data.message === 'Error: Error to login. Incorrect password') {
                        console.log(data.message)
                        document.getElementById('loginText').style.color = "red";
                        document.getElementById('loginText').textContent = translation.login_text_error;
                    } else
                        if (data.message === 'Login effettuato con successo') {
                            console.log(data.message);
                            window.location.reload();
                        } else {
                            console.log(data.message);
                            document.getElementById('loginText').style.color = "red";
                            document.getElementById('loginText').textContent = translation.login_text_error;
                            alert("Errore interno. Contattare l'amministratore di sistema");
                        }

                /*
                if (data.item === "user already logged in") {
                    console.log("user already logged in")
                    document.getElementById('loginText').style.color = "red";
                    document.getElementById('loginText').textContent = translation.login_text_already_logged
                } else
                    if (data.item !== "user already logged in" && data.item.length > 0) { console.log("login ok"); window.location.reload(); }
                if (data.item.length === 0) {
                    console.log("login fail");
                    document.getElementById('loginText').style.color = "red";
                    document.getElementById('loginText').textContent = translation.login_text_error;
                }
                */


                setButtonLoadingText(translation.login_button);
                setLoadingButton(false);

            })
            .catch((err) => {
                console.log(err);
                alert("Errore interno. Contattare l'amministratore di sistema");
                console.error("Login fallito");
                document.getElementById('loginText').style.color = "red";
                document.getElementById('loginText').textContent = translation.login_text_error;
                setLoadingButton(false);
                setButtonLoadingText(translation.login_button);
            });
    };


    const handleResetPassword = () => {
        userResetsTheirPassword(searchParams.get('event'), searchParams.get('user')).then(data => {
            alert('Abbiamo inviato una email al suo indirizzo di posta. La preghiamo di seguire le istruzioni fornite nell\'email per effettuare il reset della password');
        });
    };


    const handleResetAccessURL = () => {
        
        userResetsTheirPassword(searchParams.get('event'), searchParams.get('user')).then(data => {
            alert('Abbiamo inviato una email al suo indirizzo di posta. La preghiamo di seguire le istruzioni fornite nell\'email per recuperare il link di accesso all\'evento');
        });
        
        
    };

    const imageRef = useRef(null);


    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });


    const isLandscape = windowSize.width > windowSize.height;

    const thresholdWidth = isLandscape ? 1000 : 689;
    const thresholdHeight = isLandscape ? 736 : 1044;

    const calculateMaxWidth = () => {
        if (windowSize.height >= thresholdHeight) {
            return thresholdWidth;
        } else {
            const widthPercentage = (windowSize.height / thresholdHeight) * 100;
            return (thresholdWidth * widthPercentage) / 100;
        }
    };

    const calculateMaxHeight = () => {
        if (windowSize.width >= thresholdWidth) {
            return thresholdHeight;
        } else {
            const heightPercentage = (windowSize.width / thresholdWidth) * 100;
            return (thresholdHeight * heightPercentage) / 100;
        }
    };


    const overlayStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',

        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: calculateMaxWidth(),
        height: '100%',
        maxHeight: calculateMaxHeight(),
        //border: '2px solid #fff',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 0
    };


    const innerDivStyle = {
        position: 'relative',
        width: '100%',
        //backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    };

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <MercuryResponsivePage pageElements={pageTemplateData} loginMode={true}>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={12} >
                    <Paper style={{ alignItems: 'center', justifyContent: 'center', paddingLeft: '8px', paddingRight: '8px', backgroundColor: "transparent", boxShadow: '0 0 0 0', position: 'relative' }}>
                        <div style={overlayStyle}>
                            <div style={innerDivStyle}>
                                <Card justifyContent="center" alignItems="center" sx={{
                                    width: {
                                        xs: 320, // theme.breakpoints.up('xs')
                                        sm: 380, // theme.breakpoints.up('sm')
                                        md: 480, // theme.breakpoints.up('md')
                                        lg: 580, // theme.breakpoints.up('lg')
                                        xl: 600, // theme.breakpoints.up('xl')
                                    }, boxShadow: 0, padding: 0, borderRadius: '0px', background: "transparent"
                                }}>
                                    <CardContent sx={{ alignItems: 'center', justifyContent: 'center', paddingBottom: '16px !important' }}>
                                        <form onSubmit={onSubmit}>
                                            {/** 
                                            <Typography mt={2} className="font-replicatt" variant="h9" color="white">{translation.login_description}</Typography>
                                            <Box >
                                                <Box mt={2}>
                                                    <StyledTextField fullWidth id="password" label="Password" variant="outlined"
                                                        value={password}
                                                        InputLabelProps={{
                                                            sx: {
                                                                fontFamily: "ReplicaTT-Regular",
                                                                fontSize: "large",
                                                                "&.MuiOutlinedInput-notchedOutline": { fontSize: "28px" }
                                                            }
                                                        }}
                                                        onChange={(event) => setPassword(event.target.value)}
                                                        inputProps={{ style: { color: "white" } }}
                                                        type={showPassword ? "text" : "password"}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                    >
                                                                        {showPassword ? <Visibility sx={{ color: "white" }} /> : <VisibilityOff sx={{ color: "white" }} />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                                <Box mt={2}>
                                                    <Typography className="font-replicatt-medium" color="white" variant="h10" id="loginText" ></Typography>
                                                </Box>
                                                <Box mt={3}>
                                                    <LoadingButton className="gsd_logout_button" loading={loadingButton} loadingPosition="end" fullWidth variant="outlined" type='submit'> {buttonLoadingText} </LoadingButton>
                                                </Box>

                                                
                                               
                                            </Box>

                                            <Box m={3}>
                                                    <p
                                                        onClick={handleResetPassword}
                                                        className="reset-text"
                                                    >
                                                        Password dimenticata?
                                                    </p>
                                                </Box>
*/}
                                                <Box mt={2}>
                                                    <Typography className="font-replicatt-medium" color="white" variant="h10" id="loginText" >Clicca in basso per recuperare il link di accesso all'evento</Typography>
                                                </Box>
                                                <Box mt={3}>
                                                <Button className="gsd_logout_button" onClick={handleResetAccessURL} variant="outlined">RECUPERA LINK ACCESSO</Button>
                                                </Box>
                                        </form>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                        <img

                            ref={imageRef}
                            src={
                                isLandscape ? pageTemplateData.login_land : pageTemplateData.login_port}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '97vh',
                                display: 'block',
                                margin: 'auto',
                            }}
                        >
                        </img>
                    </Paper>
                </Grid>
            </Grid>
        </MercuryResponsivePage>
    );
}

export default Login;
