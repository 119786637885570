import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../authentication/AuthenticationProvider";
import Login from "../pages/Login";
import { useSearchParams } from "react-router-dom";
import LoginWithoutParams from "../pages/LoginWithoutParams";
import LoginWithToken from "../pages/LoginWithToken";

const RequireAuth = ({ children, pageTemplateData, eventData }) => {
    const [status, setStatus] = useState(false);
    const { getAccountData } = useContext(AccountContext);
    const [searchParams] = useSearchParams();
    const [userBackdoorAssigned, setUserBackdoorAssigned] = useState(false);

    useEffect(() => {
        const user = searchParams.get('user');
        const tkn = searchParams.get('tkn');

        //controlla lista backdoor
        const userBackdoorList = eventData?.loginBackdoor || "$$$$$$$$$$$$$$$$$$$$$$$";
        const userBackdoors = userBackdoorList.split("||").map(item => item.trim());

        if (userBackdoors.includes(user)) {
            //alert("*** USER BACKDOOR ACCESS -- SKIP LOGIN ***");
            setStatus(true);
            setUserBackdoorAssigned(true);
            return;
        }

        if (!user && !tkn) {
            //mostra `LoginWithoutParams`
            setStatus(false);
        } else if (user && tkn) {
            //mostra `LoginWithToken`
            setStatus(false);
        }
        
        getAccountData()
                .then((data) => {
                    setStatus(data.email === user);
                })
                .catch(() => setStatus(false));
        
    }, [eventData, searchParams, getAccountData]);


    if (status) {
        return children;
    }

    const user = searchParams.get('user');
    const tkn = searchParams.get('tkn');

    if (user && tkn) {
        return <LoginWithToken pageTemplateData={pageTemplateData} eventData={eventData} />;
    } else if (user) {
        return <Login pageTemplateData={pageTemplateData} eventData={eventData} />;
    } else {
        return <LoginWithoutParams pageTemplateData={pageTemplateData} eventData={eventData} />;
    }
};

export default RequireAuth;