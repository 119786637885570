import React, { useState, useContext, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField, Box, InputAdornment, IconButton, Typography, } from '@mui/material';
import { CardContent, Card } from '@mui/material';
import useTranslation from "../localization/Translations";
import { AccountContext } from "../authentication/AuthenticationProvider";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSearchParams } from "react-router-dom";
import MercuryResponsivePage from "../components/MercuryResponsivePage";


const LoginWithoutParams = ({ pageTemplateData, eventData }) => {

    const translation = useTranslation();
    const { loginUser } = useContext(AccountContext);
    const [searchParams] = useSearchParams();

    //campi del form
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //mostra - nascondi password
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    //funzioni ux del login
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [buttonLoadingText, setButtonLoadingText] = useState(translation.login_button);


    useEffect(() => {
        setButtonLoadingText(translation.login_button);
    }, [translation]);

    const onSubmit = (event) => {
        event.preventDefault();
        setButtonLoadingText(translation.login_button_wait);
        setLoadingButton(true);

        document.getElementById('loginText').style.color = "black";
        document.getElementById('loginText').textContent = "";

        loginUser(searchParams.get('event'), email, password)
            .then((data) => {
                if (data.message === 'Error: Cookie found, login refused. The user already logged in') {
                    console.log(data.message)
                    document.getElementById('loginText').style.color = "red";
                    document.getElementById('loginText').textContent = translation.login_text_already_logged
                } else
                    if (data.message === 'Error: Error to login. Incorrect password') {
                        console.log(data.message)
                        document.getElementById('loginText').style.color = "red";
                        document.getElementById('loginText').textContent = translation.login_text_error;
                    } else
                        if (data.message === 'Login effettuato con successo') {
                            console.log(data.message);
                            window.location.reload();
                        } else {
                            console.log(data.message);
                            document.getElementById('loginText').style.color = "red";
                            document.getElementById('loginText').textContent = translation.login_text_error;
                            alert("Errore interno. Contattare l'amministratore di sistema");
                        }

                /*
                if (data.item === "user already logged in") {
                    console.log("user already logged in")
                    document.getElementById('loginText').style.color = "red";
                    document.getElementById('loginText').textContent = translation.login_text_already_logged
                } else
                    if (data.item !== "user already logged in" && data.item.length > 0) { console.log("login ok"); window.location.reload(); }
                if (data.item.length === 0) {
                    console.log("login fail");
                    document.getElementById('loginText').style.color = "red";
                    document.getElementById('loginText').textContent = translation.login_text_error;
                }
                */

                setButtonLoadingText(translation.login_button);
                setLoadingButton(false);

            })
            .catch((err) => {
                console.log(err);
                alert("Errore interno. Contattare l'amministratore di sistema");
                console.error("Login fallito");
                document.getElementById('loginText').style.color = "red";
                document.getElementById('loginText').textContent = translation.login_text_error;
                setLoadingButton(false);
                setButtonLoadingText(translation.login_button);
            });
    };

    return (
        <MercuryResponsivePage pageElements={pageTemplateData} loginMode={true}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" alignItems="center"  >
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding: 1 }}>
                        <Card sx={{
                            width: {
                                xs: 320, // theme.breakpoints.up('xs')
                                sm: 380, // theme.breakpoints.up('sm')
                                md: 480, // theme.breakpoints.up('md')
                                lg: 580, // theme.breakpoints.up('lg')
                                xl: 600, // theme.breakpoints.up('xl')
                            }, boxShadow: 20, padding: 2, borderRadius: '8px', background: "rgb(248,249,252)"
                        }}>
                            <CardContent>
                                <form onSubmit={onSubmit}>
                                    <Typography mb={4} variant="h3">{eventData.name}</Typography>
                                    <Typography variant="h8" color="text.secondary">{translation.login_wp_description}</Typography>

                                    <Box mt={6} >
                                        <TextField fullWidth id="email" label={translation.login_email} variant="outlined"
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            type={email}
                                        />
                                    </Box>

                                    <Box >
                                        <Box mt={4}>
                                            <TextField fullWidth id="password" label="Password" variant="outlined"
                                                value={password}
                                                onChange={(event) => setPassword(event.target.value)}
                                                type={showPassword ? "text" : "password"}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Box>

                                        <Box mt={4}>
                                            <Typography id="loginText" variant="body1" sx={{ textDecoration: 'none' }}></Typography>
                                        </Box>
                                        <Box mt={4}>
                                            <LoadingButton loading={loadingButton} loadingPosition="end" fullWidth variant="contained" type='submit'> {buttonLoadingText} </LoadingButton>
                                        </Box>
                                    </Box>
                                </form>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </MercuryResponsivePage>
    );
}

export default LoginWithoutParams;