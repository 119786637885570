export default {
  language: "it",

  //LanguageButton
  header_lingua_impostazioni: "Impostazioni lingua",
  header_lingua_sistema: "Lingua del sistema",
  header_lingua_italiano: "Italiano",
  header_lingua_inglese: "Inglese",
  header_settings: "Impostazioni",

  //User Button
  header_account: "Account",
  header_logout: "Disconnetti",

  //Login
  login_title: "Accedi",
  login_description: "Inserire la propria password per accedere all'evento",
  login_wp_description: "Inserire il proprio username e la password per accedere all'evento",
  login_wt_description: "Clicca sul tasto in basso per accedere all'evento",
  login_email: "Indirizzo email",
  login_button: "Accedi",
  login_reset: "Password dimenticata?",
  login_button_wait: "Accesso in corso",
  login_text_error: "Impossibile accedere: username o password errata",
  login_text_error_url: "Impossibile accedere: URL non corretto",
  login_text_already_logged: "L'utente ha già effettuato l'accesso",

  countdown_text: "L'evento inizierà tra",
  countdown_days: "Giorni",
  countdown_hours: "Ore",
  countdown_minutes: "Minuti",
  countdown_seconds: "Secondi",

  //Home
  home_pleasewait: "Caricamento in corso",

  //End event
  event_end: "L'evento è terminato",
  event_end_msg: "Grazie per la visione"
};