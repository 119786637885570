import './App.css';
import RequireAuthentication from './requirements/RequireAuthentication';
import Home from './pages/Home';
import Login from './pages/Login';
import RequireEventID from './requirements/RequireEventID';
import ResetPassword from './pages/ResetPassword';
import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';


let defaultTemplateElements = {
  bg_land: "/images/static/bg_land.png",
  bg_port: "/images/static/bg_port.png",
  fg_land: "/images/static/fg_land.png",
  fg_port: "/images/static/fg_port.png",
  login_land: "/images/static/login_land.png",
  login_port: "/images/static/login_port.png",
  fg_land_cdn: "/images/static/fg_land_benvenuti.png",
  fg_port_cdn: "/images/static/fg_port_benvenuti.png",
  loading_company_logo: "/images/static/company_logo_white.png",
  company_logo: "/images/static/company_logo_white.png",
  mercury_logo: "/images/static/logo_black.png",
  button_color: "white",
  company_logo_position: "left"
}

function App() {
  const [pageTemplateData, setPageTemplateData] = React.useState(defaultTemplateElements);
  const [eventData, setEventData] = React.useState({});

  const [resetPwd, setResetPwd] = React.useState(false);
  const [recoveryUrl, setRecoveryUrl] = React.useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname.includes('/resetpwd')) {
      setResetPwd(true);
      setRecoveryUrl(false);
    }

    else if (window.location.pathname.includes('/reurl')) {
      setResetPwd(false);
      setRecoveryUrl(true);
    }
  }, [navigate]);

  return (
    <div className="App">

{recoveryUrl ? <Login pageTemplateData={pageTemplateData} />
      : (resetPwd ? <ResetPassword pageTemplateData={pageTemplateData} /> :
        <RequireEventID templateData={pageTemplateData} setTemplateData={setPageTemplateData} eventData={eventData} setEventData={setEventData}>
          <RequireAuthentication pageTemplateData={pageTemplateData} eventData={eventData}>
            <Routes>
              <Route path="/" element={
                <Home pageTemplateData={pageTemplateData} eventData={eventData} />
              } />
              <Route path="/resetpwd" element={
                <ResetPassword pageTemplateData={pageTemplateData} />
              } />

              
            </Routes>
          </RequireAuthentication>
        </RequireEventID>
      )}
    </div>
  );
}

export default App;
