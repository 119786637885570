import MercuryResponsivePage from "../components/MercuryResponsivePage";
import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useState } from 'react';

function getDeviceOrientation() {

    if (window.screen.orientation) {
        return window.screen.orientation.type;
    }

    // iOS/safari
    return Math.abs(+window.orientation) === 90 ? 'landscape-primary' : 'portrait-primary';
}


function useScreenOrientation() {
    const [orientation, setOrientation] = useState(getDeviceOrientation());


    useEffect(() => {
        const handleOrientationChange = () => setOrientation(getDeviceOrientation());
        window.addEventListener('orientationchange', handleOrientationChange);
        return () => window.removeEventListener('orientationchange', handleOrientationChange);
    }, []);

    return orientation;
}


const PageCountdownStart = ({ pageTemplateData, eventData }) => {


    return (
        <MercuryResponsivePage pageElements={pageTemplateData} accountData={""} loginMode={true}>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={12} >
                    <Paper style={{ textAlign: 'center', backgroundColor: "transparent", boxShadow: '0 0 0 0' }}>
                        <img
                            src={
                                useScreenOrientation() === "landscape-primary" ? pageTemplateData.fg_land_cdn : pageTemplateData.fg_port_cdn}
                            style={{
                                maxWidth: '96%',
                                maxHeight: '96vh',
                                display: 'block',
                                margin: 'auto',
                            }}
                        >
                        </img>

                    </Paper>
                </Grid>
            </Grid>
        </MercuryResponsivePage>
    );
}

export default PageCountdownStart;
