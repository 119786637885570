import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import LanguageContext from './localization/LanguageContext';
import { AuthenticationProvider } from './authentication/AuthenticationProvider';
import './fonts/ReplicaTT-Regular.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));

const getLanguageCookie = () => {
  let languageCookie = localStorage.getItem("language");
  if(languageCookie === null) return "it";
  return languageCookie;
}

root.render(
  // <React.StrictMode> rimozione predicato causa doppio rendering di useEffect
  <LanguageContext languageCookie={getLanguageCookie()}>
    <AuthenticationProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthenticationProvider>
  </LanguageContext>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
