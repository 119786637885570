import React, { useContext } from "react";
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import LanguageIcon from '@mui/icons-material/Language';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useEffect } from 'react';
import { useLanguageContext } from "../localization/LanguageContext";
import useTranslation from "../localization/Translations";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Stack } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { AccountContext } from "../authentication/AuthenticationProvider";
import { useSearchParams } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MercuryHeaderButton = ({ buttonColor, showAccountButton, portraitMode, accountData }) => {
  const translation = useTranslation();
  const { logoutUser } = useContext(AccountContext);
  const [searchParams] = useSearchParams();
  const { language, changeLanguage } = useLanguageContext();
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
  const [anchorElAccount, setAnchorElAccount] = React.useState(null);

  useEffect(() => {
    if (language === "it")
      setSelectedIndex(0);
    if (language === "en")
      setSelectedIndex(1);
  });

  const itemsLanguageList = [
    { text: translation.header_lingua_italiano, icon: <img src="/images/static/language_flags/italian.png" width="24" height="24" />, active: selectedIndex === 0, onclick: () => { changeLanguage("it") } },
    { text: translation.header_lingua_inglese, icon: <img src="/images/static/language_flags/english.png" width="24" height="24" />, active: selectedIndex === 1, onclick: () => { changeLanguage("en") } },
  ];

  const itemsAccountList = [
    { text: translation.header_logout, icon: <LogoutIcon />, onclick: () => { logoutButtonHandle(); setAnchorElAccount(null); } },
  ];


  const handleOpenLanguageMenu = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguage(null);
  };


  const handleOpenAccountMenu = (event) => {
    setAnchorElAccount(event.currentTarget);
  };

  const handleCloseAccountMenu = () => {
    setAnchorElAccount(null);
  };


  const logoutButtonHandle = () => {
    logoutUser(searchParams.get('event'), accountData.email);
  }

  return (
    <Box sx={{ flexGrow: 1, textAlign: 'right' }} >
      {portraitMode ? <>
        <Tooltip title={translation.header_settings}>
          <IconButton onClick={handleOpenAccountMenu} sx={{ p: 0 }}>
            <MoreVertIcon style={{ color: buttonColor }} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElAccount}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElAccount)}
          onClose={handleCloseAccountMenu}
        >
          {
            <List>
              <Box mb={2}>
                <Grid container spacing={1} direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid item xs="auto" md={4}>
                    <img src="/images/static/account.png" width="48px" />
                  </Grid>
                  <Grid item xs={12} md={8} >
                    <Box mt={-0.5}>

                      <Typography mt={0.5} align="center" variant="body2">
                        {accountData.email}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <List>

                {itemsAccountList.map((item, index) => {
                  const { text, icon, active, onclick } = item;
                  return (
                    <ListItemButton
                      sx={{
                        mb: 2,
                        "&.Mui-selected": {
                          color: "rgb(25,118,210)", '& .MuiListItemIcon-root': {
                            color: "rgb(25,118,210)"
                          },
                          '& .MuiTypography-root': {
                          }
                        }
                      }}
                      key={text} selected={active} onClick={onclick}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  );
                })}
                <Divider />
                <Typography align="center" sx={{ mt: 2, ml: 2, mr: 2, mb: 2 }} variant="body2">
                  {translation.header_lingua_sistema}
                </Typography>
              </List>
              {itemsLanguageList.map((item, index) => {
                const { text, icon, active, onclick } = item;
                return (
                  <ListItemButton
                    sx={{
                      "&.Mui-selected": {
                        color: "rgb(25,118,210)", '& .MuiListItemIcon-root': {
                          color: "rgb(25,118,210)"
                        },
                        '& .MuiTypography-root': {
                        }
                      }
                    }}
                    key={text} selected={active} onClick={onclick}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                );
              })}



              <Box sx={{ padding: 2 }} mb={-2}>
                
                <Typography color="text.secondary" align="center" variant="body2">
                  Copyright © Lantech Longwave S.p.A. - 2024
                </Typography>
                <Typography color="text.secondary" align="center" variant="body2">
                  www.lantechlongwave.it
                </Typography>
              </Box>
            </List>
          }
        </Menu>
      </> : <>
        {showAccountButton ? <>
          <Tooltip title={translation.header_account}>
            <IconButton onClick={handleOpenAccountMenu} sx={{ p: 0 }}>
              <AccountCircleIcon style={{ color: buttonColor }} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElAccount}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElAccount)}
            onClose={handleCloseAccountMenu}
          >
            {
              <List>
                <Box p={1} mb={1}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                        <img src="/images/static/account.png" width="48px" />
                        <Typography variant="body2">
                          {accountData.email}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                {itemsAccountList.map((item, index) => {
                  const { text, icon, active, onclick } = item;
                  return (
                    <ListItemButton
                      sx={{
                        mt: 2,
                        "&.Mui-selected": {
                          color: "rgb(25,118,210)", '& .MuiListItemIcon-root': {
                            color: "rgb(25,118,210)"
                          },
                          '& .MuiTypography-root': {
                          }
                        }
                      }}
                      key={text} selected={active} onClick={onclick}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  );
                })}
                <Box sx={{ padding: 2 }} mb={-2}>
                  <Typography color="text.secondary" align="center" variant="body2">
                    Copyright © Lantech Longwave S.p.A. - 2024
                  </Typography>
                  <Typography color="text.secondary" align="center" variant="body2">
                    www.lantechlongwave.it
                  </Typography>
                </Box>
              </List>
            }
          </Menu> </>
          : <div />}

        <Tooltip title={translation.header_lingua_impostazioni}>
          <IconButton onClick={handleOpenLanguageMenu} sx={{ p: 0, ml: 2 }}>
            <LanguageIcon style={{ color: buttonColor }} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElLanguage}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElLanguage)}
          onClose={handleCloseLanguageMenu}
        >
          {
            <List>
              <Typography sx={{ ml: 2, mr: 2, mb: 2 }} variant="body2">
                {translation.header_lingua_sistema}
              </Typography>
              {itemsLanguageList.map((item, index) => {
                const { text, icon, active, onclick } = item;
                return (
                  <ListItemButton
                    sx={{
                      "&.Mui-selected": {
                        color: "rgb(25,118,210)", '& .MuiListItemIcon-root': {
                          color: "rgb(25,118,210)"
                        },
                        '& .MuiTypography-root': {
                        }
                      }
                    }}
                    key={text} selected={active} onClick={onclick}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                );
              })}
            </List>
          }
        </Menu>
      </>}
    </Box>
  );
}

export default MercuryHeaderButton;
