export default {
    language: "en",

    //LanguageButton
    header_lingua_impostazioni: "Language settings",
    header_lingua_sistema: "System language",
    header_lingua_italiano: "Italian",
    header_lingua_inglese: "English",
    header_settings: "Settings",

    //User Button
    header_account: "User",
    header_logout: "Logout",

    //Login
    login_title: "Login",
    login_description: "Enter your password to access the event",
    login_wp_description: "Enter your username and password to access the event",
    login_wt_description: "Click on the button below to access the event",
    login_email: "Email address",
    login_button: "Login",
    login_reset: "Forgotten password?",
    login_button_wait: "Logging in",
    login_text_error: "Unable to log in: wrong username or password",
    login_text_error_url: "Unable to log in: URL incorrect",
    login_text_already_logged: "The user is already logged in",

    countdown_text: "Event starts in",
    countdown_days: "Days",
    countdown_hours: "Hours",
    countdown_minutes: "Minutes",
    countdown_seconds: "Seconds",

    //Home
    home_pleasewait: "Please wait",

    //End event
    event_end: "Event ended",
    event_end_msg: "Thanks for watching"
};